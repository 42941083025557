import React, { Fragment } from 'react'
import styled, { css } from 'styled-components'
import { Link } from '@reach/router'

// utils and config
import th from 'utils/themeHelper'
import constants from 'config/constants'

// Load components synchronously
import Tooltip from 'components/Tooltip'

// icons
import { CalendarEventFill } from '@styled-icons/bootstrap/CalendarEventFill'
import { CheckBox, CheckBoxOutlineBlank } from '@styled-icons/material'
import { CreativeCommonsZero } from '@styled-icons/fa-brands/CreativeCommonsZero'
import { Cancel } from '@styled-icons/material/Cancel'
import { AdminPanelSettings } from '@styled-icons/material/AdminPanelSettings'
import { StopwatchFill } from '@styled-icons/bootstrap/StopwatchFill'
import { ShoePrints } from '@styled-icons/fa-solid/ShoePrints'
import { Lock } from '@styled-icons/boxicons-solid/Lock'
import { DocumentPerson } from '@styled-icons/fluentui-system-filled/DocumentPerson'
import { HouseDoorFill } from '@styled-icons/bootstrap/HouseDoorFill'
import { Check } from '@styled-icons/material/Check'
import { Close } from '@styled-icons/evil/Close'
import { BodyText } from '@styled-icons/bootstrap/BodyText'
import { PeopleFill } from '@styled-icons/bootstrap'
import { BuildingHouse } from '@styled-icons/boxicons-regular/BuildingHouse'
import { PercentOutline } from '@styled-icons/evaicons-outline/PercentOutline'
import { DollarCircle } from '@styled-icons/boxicons-regular/DollarCircle'
import { GraphBar } from '@styled-icons/foundation/GraphBar'
import { RatioOneToOne } from '@styled-icons/fluentui-system-regular/RatioOneToOne'
import { TextLeft } from '@styled-icons/bootstrap/TextLeft'
import { ListOl } from '@styled-icons/fa-solid/ListOl'
import { TextBulletListLtr } from '@styled-icons/fluentui-system-filled/TextBulletListLtr'
import { ListColumns } from '@styled-icons/bootstrap/ListColumns'
import { CheckboxBlankCircle } from '@styled-icons/remix-line/CheckboxBlankCircle'

const primaryBaseFaded = '#379B75BB'
const secondaryBaseFaded = '#8CCA1155'

const lowercaseValue = value => {
  if (value === null) return ''

  if (typeof value === 'undefined') return ''
  if (typeof value === 'number') return `${value}`
  if (typeof value === 'boolean') return `${value}`
  if (typeof value === 'object') return ''

  return value.toLowerCase()
}

const getMultiPillsPillColor = ({ bgColor, color, item, columnKey }) => {
  const lowercased = lowercaseValue(item)

  if (columnKey === 'zero_rating_status') {
    const isApproved = !!lowercased.match('zero-rated')
    const isInvalid = !!lowercased.match('invalid')
    const isPending = !!lowercased.match('pending')
    const verificationRequired = !!lowercased.match('pbo verification required')

    const multiPillBgColor = isApproved
      ? th('secondary.dark')
      : isInvalid
      ? th('backgrounds.warning')
      : isPending
      ? th('primary.orange')
      : verificationRequired
      ? th('backgrounds.warning')
      : 'white'
    const multiPillColor = isApproved
      ? 'white'
      : isInvalid
      ? 'white'
      : isPending
      ? 'white'
      : verificationRequired
      ? 'white'
      : th('text.dark')

    return { multiPillBgColor, multiPillColor }
  }

  if (columnKey === 'website_statuses') {
    const isApproved = !!lowercased.match('approved') || !!lowercased.match('listed')
    const isInvalid = !!lowercased.match('invalid')
    const isPending = !!lowercased.match('pending')

    const multiPillBgColor = isApproved
      ? th('secondary.dark')
      : isInvalid
      ? th('backgrounds.warning')
      : isPending
      ? th('primary.orange')
      : 'white'
    const multiPillColor = isApproved
      ? 'white'
      : isInvalid
      ? 'white'
      : isPending
      ? 'white'
      : th('text.dark')

    return { multiPillBgColor, multiPillColor }
  }

  if (columnKey === 'budget_categories') {
    const multiPillBgColor = 'transparent'
    const multiPillColor = th('text.dark')

    return { multiPillBgColor, multiPillColor }
  }

  return { multiPillBgColor: bgColor, multiPillColor: color }
}

export const getPillColors = ({ headerMode, key, value }) => {
  const lowercased = lowercaseValue(value)
  const isPrimaryPill = ['group_name', 'province'].includes(key)

  if (headerMode === 'icon-headers-mode' && key === 'agreement_context_type') {
    return { bgColor: 'transparent', color: th('text.dark') }
  }

  if (key === 'agreement_context_type') {
    const isSkillLevel = !!lowercased.match('skill level')

    if (isSkillLevel) {
      return { bgColor: th('backgrounds.seaBreeze'), color: th('text.dark') }
    }

    return { bgColor: 'transparent', color: th('text.dark') }
  }

  if (key === 'outputs_tracking_status') {
    const overdue = !!lowercased.match('overdue')
    const upToDate = !!lowercased.match('up-to-date')
    const noIndicators = !!lowercased.match('no-indicators')
    const noOutputs = !!lowercased.match('no-outputs')

    const bgColor = upToDate
      ? th('primary.base')
      : overdue
      ? th('backgrounds.warning')
      : noOutputs
      ? th('primary.orange')
      : noIndicators
      ? th('backgrounds.mid')
      : '#379B7599'
    const color = 'white'

    return { bgColor, color }
  }

  if (key === 'zero_rated_website_url') {
    return { bgColor: 'transparent', color: th('text.dark') }
  }

  if (key === 'primary_email' && !value) {
    return { bgColor: th('backgrounds.warning'), color: 'white' }
  }

  if (key === 'budget_category_visibility') {
    const isVisible = !!lowercased.match('visible')

    return {
      bgColor: isVisible ? th('primary.base') : th('backgrounds.mid'),
      color: isVisible ? 'white' : th('text.dark')
    }
  }

  if (key === 'pbo_verification_status') {
    const isVerified = !!lowercased.match('verified')
    const isUnverified = !!lowercased.match('unverified')
    const hasRequested = !!lowercased.match('requested')

    const bgColor = hasRequested
      ? th('primary.orange')
      : isUnverified
      ? th('backgrounds.warning')
      : isVerified
      ? th('secondary.dark')
      : 'white'
    const color = hasRequested
      ? 'white'
      : isVerified
      ? 'white'
      : isUnverified
      ? 'white'
      : th('text.dark')

    return { bgColor, color }
  }

  if (key === 'zero_rating_status') {
    const isZeroRated = lowercased === 'zero rated'
    if (isZeroRated) {
      return { bgColor: 'gold', color: th('text.dark') }
    }

    const isInvalid = lowercased === 'invalid'
    if (isInvalid) {
      return { bgColor: th('backgrounds.warning'), color: 'white' }
    }

    const isPending = lowercased === 'pending'
    if (isPending) {
      return { bgColor: th('primary.orange'), color: 'white' }
    }

    return { bgColor: 'white', color: th('text.dark') }
  }

  if (key === 'purchase_request_status') {
    const hasApproved = lowercased === 'approved'
    const hasDenied = lowercased === 'denied'
    const hasPaid = lowercased === 'paid'
    const hasCompleted = lowercased === 'completed'
    const hasSubmitted = lowercased === 'submitted'
    const bgColor = hasApproved
      ? th('secondary.base')
      : hasDenied
      ? th('backgrounds.warning')
      : hasPaid
      ? 'gold'
      : hasCompleted
      ? primaryBaseFaded
      : hasSubmitted
      ? primaryBaseFaded
      : th('borders.default')
    const color = hasApproved
      ? 'white'
      : hasSubmitted
      ? 'white'
      : hasCompleted
      ? 'white'
      : hasDenied
      ? 'white'
      : th('text.dark')

    return { bgColor, color }
  }

  if (key === 'application_status') {
    const isDraft = lowercased === 'draft'
    const isPending = lowercased === 'pending'
    const isInvalid = lowercased === 'invalid'
    const hasRejected = lowercased === 'rejected'
    const isCompleted = lowercased === 'completed'

    const bgColor = isDraft
      ? th('borders.default')
      : isPending
      ? th('secondary.lightest')
      : hasRejected
      ? th('backgrounds.warning')
      : isInvalid
      ? th('backgrounds.warning')
      : isCompleted
      ? th('secondary.dark')
      : th('borders.default')
    const color = isPending ? th('text.dark') : isDraft ? th('text.dark') : 'white'

    return { bgColor, color }
  }

  if (key === 'agreement_template_status') {
    const isActive = lowercased === 'active'
    const isInative = lowercased === 'inactive'

    const bgColor = isActive
      ? th('secondary.dark')
      : isInative
      ? th('backgrounds.warning')
      : th('borders.default')
    const color = isActive ? 'white' : isInative ? 'white' : th('text.dark')

    return { bgColor, color }
  }

  if (key === 'member_roles') {
    const isTimekeeper = lowercased === 'timekeeper'
    const isPartnerAdmin = lowercased === 'partner admin'
    const isWorksiteAdmin = lowercased === 'worksite admin'
    const isWorkspaceAdmin = lowercased === 'workspace admin'
    const bgColor = isWorkspaceAdmin
      ? 'gold'
      : isTimekeeper
      ? '#379B7566'
      : isPartnerAdmin
      ? '#379B7566'
      : isWorksiteAdmin
      ? '#379B7566'
      : th('borders.default')
    const color = isWorkspaceAdmin
      ? th('text.dark')
      : isTimekeeper
      ? th('text.dark')
      : th('text.dark')

    return { bgColor, color }
  }

  if (key === 'is_onboarded') {
    const isGreen = lowercased === 'onboarded'
    const bgColor = isGreen ? primaryBaseFaded : th('borders.default')
    const color = isGreen ? 'white' : th('text.dark')

    return { bgColor, color }
  }

  if (key === 'salaried_rate_type') {
    const isHourly = lowercased === 'hourly'
    const bgColor = isHourly ? primaryBaseFaded : th('borders.default')
    const color = isHourly ? 'white' : th('text.dark')

    return { bgColor, color }
  }

  if (key === 'category') {
    return { bgColor: '#379B7566', color: th('text.dark') }
  }

  if (key === 'area_name') {
    return { bgColor: '#379B7566', color: th('text.dark') }
  }

  if (key === 'member_role') {
    return { bgColor: '#379B7566', color: th('text.dark') }
  }

  if (key === 'membership_status') {
    const hasJoined = lowercased === 'joined'
    const hasDeclined = lowercased === 'declined'
    const hasBeenApproved = lowercased === 'approved'
    const hasBeenRejected = lowercased === 'rejected'
    const hasBeenRemoved = lowercased === 'removed'
    const bgColor = hasJoined
      ? 'gold'
      : hasBeenApproved
      ? 'gold'
      : hasDeclined
      ? th('backgrounds.warning')
      : hasBeenRejected
      ? th('backgrounds.warning')
      : hasBeenRemoved
      ? th('backgrounds.warning')
      : th('borders.default')
    const color = hasDeclined
      ? 'white'
      : hasBeenRejected
      ? 'white'
      : hasBeenRemoved
      ? 'white'
      : th('text.dark')

    return { bgColor, color }
  }

  if (isPrimaryPill) {
    const bgColor = primaryBaseFaded
    const color = 'white'

    return { bgColor, color }
  }

  if (key === 'faq_status_pill') {
    const isPublic = lowercased === 'public'
    const bgColor = isPublic ? primaryBaseFaded : th('borders.default')
    const color = isPublic ? 'white' : th('text.dark')

    return { bgColor, color }
  }

  if (key === 'subscription_status') {
    const bgColor =
      lowercased === 'active'
        ? primaryBaseFaded
        : lowercased === 'paused'
        ? th('borders.default')
        : lowercased === 'cancelled'
        ? th('backgrounds.dark')
        : th('borders.default')

    const color =
      lowercased === 'active'
        ? 'white'
        : lowercased === 'paused'
        ? th('text.dark')
        : lowercased === 'cancelled'
        ? 'white'
        : th('text.dark')

    return { bgColor, color }
  }

  if (key === 'volunteering_status') {
    const isJoined = value === constants.APPLICATION_STATUS_COLUMN_NAMES.JOINED
    const isDone = value === constants.APPLICATION_STATUS_COLUMN_NAMES.PARTICIPATED
    const isRed = [
      constants.APPLICATION_STATUS_COLUMN_NAMES.SKIPPED,
      constants.APPLICATION_STATUS_COLUMN_NAMES.CANCELLED
    ].includes(value)

    const bgColor = isJoined
      ? th('transparent.base')
      : isDone
      ? th('primary.base')
      : isRed
      ? th('backgrounds.warning')
      : th('transparent.base')

    const color = isJoined ? th('text.dark') : isDone ? 'white' : isRed ? 'white' : th('text.dark')

    return { bgColor, color }
  }

  if (key === 'contribution_status') {
    const bgColor =
      lowercased === 'withdrew' || lowercased === 'rejected'
        ? th('backgrounds.warning')
        : lowercased === 'pending' || lowercased === 'pendingdonation'
        ? primaryBaseFaded
        : lowercased === 'pending approval'
        ? secondaryBaseFaded
        : lowercased === 'committed'
        ? th('borders.default')
        : lowercased === 'confirmed'
        ? 'gold'
        : th('borders.default')

    const color =
      lowercased === 'withdrew' || lowercased === 'rejected'
        ? 'white'
        : lowercased === 'pending' || lowercased === 'pendingdonation'
        ? 'white'
        : lowercased === 'pending approval'
        ? th('text.dark')
        : lowercased === 'confirmed'
        ? th('text.dark')
        : lowercased === 'committed'
        ? th('text.dark')
        : 'white'

    return { bgColor, color }
  }

  if (key === 'status') {
    const bgColor = ['active', 'pending round'].includes(lowercased)
      ? primaryBaseFaded
      : lowercased === 'withdrew'
      ? th('backgrounds.warning')
      : lowercased === 'skipped'
      ? th('primary.orange')
      : lowercased === 'draft'
      ? th('secondary.lightest')
      : ['completed', 'confirmed', 'winner'].includes(lowercased)
      ? 'gold'
      : th('borders.default')

    const color = ['active', 'pending round', 'skipped', 'withdrew'].includes(lowercased)
      ? 'white'
      : lowercased === 'draft'
      ? th('text.dark')
      : lowercased === 'completed'
      ? th('text.dark')
      : lowercased === 'confirmed'
      ? th('text.dark')
      : th('text.dark')

    return { bgColor, color }
  }

  if (key === 'is_missing_details') {
    const bgColor = lowercased === 'missing info' ? th('backgrounds.warning') : 'gold'
    const color = lowercased === 'missing info' ? 'white' : th('text.dark')

    return { bgColor, color }
  }

  if (key === 'org_type') {
    const bgColor = ['nonprofit', 'pbo'].includes(lowercased)
      ? 'gold'
      : lowercased === 'business'
      ? th('backgrounds.violet')
      : lowercased === 'school'
      ? th('backgrounds.seaBreeze')
      : th('borders.default')
    const color = th('text.dark')

    return { bgColor, color }
  }

  if (key === 'story_status') {
    const bgColor =
      lowercased === constants.STORY_STATES.PUBLIC.toLowerCase()
        ? primaryBaseFaded
        : ['action only', 'title only'].includes(lowercased)
        ? '#379B7566'
        : th('borders.default')

    const color =
      lowercased === constants.STORY_STATES.PUBLIC.toLowerCase()
        ? 'white'
        : ['action only', 'title only'].includes(lowercased)
        ? th('text.dark')
        : th('text.dark')

    return { bgColor, color }
  }

  if (key === 'visibility_status') {
    const bgColor =
      lowercased === constants.PERSONAL_GOAL_VISIBILITY_OPTS.PUBLIC.toLowerCase()
        ? primaryBaseFaded
        : ['action only', 'title only'].includes(lowercased)
        ? '#379B7566'
        : th('borders.default')

    const color =
      lowercased === constants.PERSONAL_GOAL_VISIBILITY_OPTS.PUBLIC.toLowerCase()
        ? 'white'
        : ['action only', 'title only'].includes(lowercased)
        ? th('text.dark')
        : th('text.dark')

    return { bgColor, color }
  }

  if (key === 'visibility_status_pill') {
    const bgColor = lowercased === 'public' ? primaryBaseFaded : th('primary.orange')

    return { bgColor, color: 'white' }
  }

  if (key === 'payroll_status') {
    const bgColor =
      lowercased === 'n/a'
        ? th('backgrounds.mid')
        : lowercased === 'unpaid'
        ? th('backgrounds.warning')
        : primaryBaseFaded
    const color = lowercased === 'n/a' ? th('text.dark') : 'white'

    return { bgColor, color }
  }

  if (key === 'total_payable_string') {
    // return { bgColor: th('borders.default'), color: th('text.dark') }

    const bgColor = lowercased === 'r0.00' ? secondaryBaseFaded : th('transparent.error')
    // const color = lowercased === 'n/a' ? th('text.dark') : 'white'
    const color = th('text.dark')

    return { bgColor, color }
  }

  if (key === 'output_tracking_frequency_short' || key === 'output_tracking_frequency') {
    const bgColor = ['m', 'monthly'].includes(lowercased)
      ? th('primary.base')
      : th('primary.orange')

    return { bgColor, color: 'white' }
  }

  if (key === 'output_tracking_day') {
    // check weekday names
    const bgColor = lowercased.match('n/a')
      ? th('backgrounds.mid')
      : lowercased.match('day')
      ? '#E8A01D66'
      : '#379B7566'

    return { bgColor, color: th('text.dark') }
  }

  if (['sef_agreement_signed'].includes(key)) {
    const bgColor =
      lowercased === 'unsigned'
        ? th('backgrounds.warning')
        : lowercased === 'terminated'
        ? '#E8A01D66'
        : primaryBaseFaded

    return { bgColor, color: lowercased === 'terminated' ? th('text.dark') : 'white' }
  }

  if (
    [
      'purchase_requests_enabled',
      'district_outputs_enabled',
      'worksite_outputs_enabled',
      'participant_management_enabled'
    ].includes(key)
  ) {
    const bgColor = lowercased === 'yes' ? primaryBaseFaded : th('backgrounds.warning')

    return { bgColor, color: 'white' }
  }

  if (key.match(/_pill$/)) {
    const bgColor = primaryBaseFaded
    const color = 'white'

    return { bgColor, color }
  }

  return { bgColor: th('borders.default'), color: th('text.dark') }
}

const CustomStatusPill = styled.div``

const CustomStatusPillImage = styled.img`
  border-radius: 50%;
  filter: ${props => (props.value === 'Pending' ? 'grayscale(1)' : 'none')};
  height: 30px;
  width: 30px;
`

export const AdminPanelIcon = styled(AdminPanelSettings)`
  background: ${props => props.background};
  border-radius: 50%;
  color: ${th('borders.dark')};
  height: 25px;
  padding: 2px;
  width: 25px;
`

export const SvgStopWatchFill = styled(StopwatchFill)`
  color: ${th('borders.dark')};
  height: 25px;
  width: 25px;
`

export const SvgBuildingHouse = styled(BuildingHouse)`
  background: ${props => props.background};
  border-radius: 50%;
  color: ${th('borders.dark')};
  height: 25px;
  padding: 4px;
  width: 25px;
`

export const SvgLock = styled(Lock)`
  background: white;
  border: 1px solid ${th('borders.medium')};
  border-radius: 50%;
  color: ${th('borders.dark')};
  height: 18px !important;
  padding: 1px 2px 2px;
  position: absolute;
  right: -6px;
  top: -6px;
  width: 18px !important;
`

export const SvgDocumentPerson = styled(DocumentPerson)`
  background: ${props => props.background};
  border-radius: 50%;
  color: ${th('borders.dark')};
  height: 25px;
  padding: 4px;
  width: 25px;
`

export const SvgCheck = styled(Check)`
  border-radius: 50%;
  color: ${th('primary.base')};
  height: 25px;
  width: 25px;
`

export const SvgClose = styled(Close)`
  border-radius: 50%;
  color: ${th('borders.error')};
  height: 25px;
  width: 25px;
`

export const SvgHouseDoorFill = styled(HouseDoorFill)`
  color: ${th('borders.dark')};
  height: 25px;
  width: 25px;
  background: #f3702050;
  padding: 4px;
  border-radius: 50%;
`

export const SvgPeopleFill = styled(PeopleFill)`
  border: 1px solid ${th('borders.medium')};
  border-radius: 50%;
  color: ${th('borders.dark')};
  height: 25px;
  padding: 4px;
  width: 25px;
`

export const SvgCheckboxBlankCircle = styled(CheckboxBlankCircle)`
  color: ${th('borders.medium')};
  height: 25px;
  width: 25px;
`

export const SvgShoePrints = styled(ShoePrints)`
  border: 1px solid ${th('borders.medium')};
  border-radius: 50%;
  color: ${th('borders.dark')};
  height: 25px;
  padding: 4px;
  width: 25px;
`

export const SvgIconCal = styled(CalendarEventFill)`
  color: ${th('borders.dark')};
  height: 25px;
  width: 25px;
`

export const SvgIconBodyText = styled(BodyText)`
  color: ${th('borders.dark')};
  height: 25px;
  width: 25px;
`

export const SvgIconCancel = styled(Cancel)`
  color: ${th('borders.dark')};
  height: 25px;
  width: 25px;
`

export const SvgIconZero = styled(CreativeCommonsZero)`
  color: ${th('borders.dark')};
  height: 25px;
  width: 25px;
`

export const Checked = styled(CheckBox)`
  color: ${th('borders.dark')};
  height: 25px;
  width: 25px;
`

export const Unchecked = styled(CheckBoxOutlineBlank)`
  color: ${th('borders.medium')};
  height: 25px;
  width: 25px;
`

const determineCardFlex = ({ showFilters }) => {
  if (showFilters === 'false') {
    return { default: '0 0 100%', w600: '0 0 calc(50% - 20px)', w969: '0 0 calc(25% - 20px)' }
  }

  return { default: '0 0 100%', w600: '0 0 calc(50% - 20px)', w969: '0 0 calc(33% - 20px)' }
}

const Table = styled.table`
  border-collapse: collapse;
  padding: 10px;
  table-layout: fixed;
`

export const DashHomeStatsTableRow = styled.tr`
  height: 50px;
  position: ${props => (props.isSticky ? 'sticky' : 'relative')};
  top: 0;
  z-index: ${props => (props.isSticky ? 2 : 1)};

  &:nth-child(odd) {
    background: ${th('backgrounds.light')};
  }

  @media screen and (min-width: 769px) {
    position: relative;
  }
`

export const DashStatTableSubTitle = styled.th`
  font-family: ${th('fonts.light')};
  font-size: 10px;
  left: 0;
  position: absolute;
  width: 100%;
`

export const DashStatTableHeading = styled.th`
  font-family: ${th('fonts.bold')};
  font-size: ${props => props.headerFontSize || '15px'};
  width: ${props =>
    ['collapse', 'small', 'smallButWrap'].includes(props.size)
      ? 'auto'
      : props.primaryCell
      ? '100%'
      : 'auto'};
  padding: ${props =>
    props.padding || (props.headerFontSize === '12px' ? '15px 10px' : '15px 6px')};
  position: relative;
  text-align: ${props =>
    props.cellAlign === 'right' ? 'right' : props.center ? 'center' : 'left'};
  text-transform: uppercase;
  white-space: ${props =>
    props.forceNoWrap
      ? 'nowrap'
      : props.useHeaderIcons
      ? 'normal'
      : props.size === 'small'
      ? 'nowrap'
      : 'normal'};
`

const CustomStatusPillValue = styled.div`
  background-color: ${props => props.bgColor || 'white'};
  border-radius: 2px;
  color: ${props => props.fontColor || th('text.dark')};
  line-height: 1;
  padding: ${props => (props.customPillCount === 1 ? '5px 4px' : '3px 4px 2px')};
`

const CustomMultiPill = styled.div`
  display: inline-block;
  font-size: 9px;
  margin-bottom: 5px;
  padding: ${props => (props.customPillCount ? '5px 5px 0' : '5px 5px 0')};
  text-align: center;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'inherit')};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
`

const MultiPill = styled.div`
  background: ${props => props.bgColor || th('backgrounds.default')};
  border: ${props => (props.columnKey === 'budget_categories' ? '1px solid #484848' : 'none')};
  color: ${props => props.fontColor || th('text.dark')};
  cursor: ${props => props.cursor || 'default'};
  border-radius: 3px;
  display: inline-block;
  font-size: 9px;
  margin-right: 5px;
  padding: ${props => (props.cursor ? '0' : '2px 5px')};
  text-align: center;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'inherit')};
  width: ${props => (props.fullWidth ? '100%' : props.pillWidth || 'auto')};
`

const Pill = styled.div`
  background: ${props => props.bgColor || th('backgrounds.default')};
  color: ${props => props.fontColor || th('text.dark')};
  border-radius: 3px;
  display: inline-block;
  font-family: ${props => (props.fontBold ? th('fonts.bold') : th('fonts.regular'))};
  font-size: 9px;
  padding: 2px 5px;
  text-align: center;
  text-transform: ${props => (props.uppercase ? 'uppercase' : 'inherit')};
  width: ${props => (props.fullWidth ? '100%' : 'auto')};

  svg {
    width: 15px;
    height: 15px;
  }
`

const NullIconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: ${props => (props.nullTextAlign === 'left' ? 'flex-start' : 'center')};

  img,
  svg {
    height: 20px;
    width: 20px;
  }
`

const IconWrapper = styled.div`
  img {
    height: ${props => (props.smallIcon ? '10px' : '20px')};
    width: ${props => (props.smallIcon ? '10px' : '20px')};
  }

  svg {
    height: ${props => (props.smallIcon ? '10px' : '20px')};
    width: ${props => (props.smallIcon ? '10px' : '20px')};
  }

  @media screen and (min-width: 769px) {
    img {
      height: ${props => (props.smallIcon ? '15px' : '30px')};
      width: ${props => (props.smallIcon ? '15px' : '30px')};
    }

    svg {
      height: ${props => (props.smallIcon ? '15px' : '30px')};
      width: ${props => (props.smallIcon ? '15px' : '30px')};
    }
  }
`

const ColumnHint = styled.span`
  font-family: ${th('fonts.italic')};
`

const TableCellImage = styled.img`
  border-radius: ${props => (props.round ? '50%' : '0')};
  height: 30px;
  width: 30px;
`

const TableCellSvgImage = styled.div`
  background: ${props => (props.avatarStyles ? props.avatarStyles.background : 'transparent')};
  border-color: ${props => (props.avatarStyles ? props.avatarStyles.borderColor : 'transparent')};
  border-radius: ${props => (props.avatarStyles ? props.avatarStyles.borderRadius : '0')};
  border-style: solid;
  border-width: ${props => (props.avatarStyles ? props.avatarStyles.borderWidth : '0')};
  height: ${props => (props.avatarStyles ? props.avatarStyles.height : '30px')};
  padding: ${props => (props.avatarStyles ? props.avatarStyles.padding : '0')};
  width: ${props => (props.avatarStyles ? props.avatarStyles.width : '30px')};
`

const NullText = styled.span`
  color: ${th('text.light')};
  font-family: ${th('fonts.italic')};
`

const ExternalLinkButtonStub = styled.div`
  align-items: center;
  background: ${th('backgrounds.mid')};
  border-radius: 4px;
  color: ${th('text.dark')};
  display: flex;
  min-height: 35px;
  padding: 6px 12px;

  &:hover {
    background: ${th('transparent.error')};
    cursor: not-allowed;
  }
`

const ExternalLinkButtonComponent = styled.a`
  align-items: center;
  background: white;
  border: 1px solid ${th('borders.default')};
  border-radius: 4px;
  color: ${th('text.dark')};
  cursor: pointer;
  display: flex;
  min-height: 35px;
  padding: 6px 12px;

  &:hover {
    background: ${th('primary.base')};
    border-color: ${th('borders.dark')};
    color: white;
  }
`

export const ExternalLinkButton = ({ children, disabled, href }) => {
  if (disabled) {
    return (
      <ExternalLinkButtonStub>
        <div style={{ height: '23px' }}>{children}</div>
      </ExternalLinkButtonStub>
    )
  }

  return (
    <ExternalLinkButtonComponent href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </ExternalLinkButtonComponent>
  )
}

const CustomPillIconSelection = ({ item }) => {
  if (!item.iconName) return null

  switch (item.iconName) {
    case 'Onboarded':
      return <SvgCheck />
    case 'Not onboarded':
      return <SvgClose />
    case 'Workspace Admin':
      return <AdminPanelIcon background={'gold'} />
    case 'Partner Admin':
      return <AdminPanelIcon background={'#379b7550'} />
    case 'Nonprofit Admin':
      return (
        <div style={{ position: 'relative' }}>
          <SvgBuildingHouse background="#379b7550" />
          <SvgLock />
        </div>
      )
    case 'District Outputs Admin':
      return <SvgDocumentPerson background="#8CCA1188" />
    case 'Worksite Outputs Admin':
      return <SvgBuildingHouse background="#e8a01d88" />
    case 'Worksite People Admin':
      return <SvgStopWatchFill />
    case 'Member':
      return <SvgPeopleFill />
    case 'SefIndicatorPercentage':
      return <PercentOutline />
    case 'SefIndicatorMoney':
      return <DollarCircle />
    case 'SefIndicatorAbsolute':
      return <GraphBar />
    case 'SefIndicatorRatio':
      return <RatioOneToOne />
    case 'SefIndicatorText':
      return <TextLeft />
    case 'SefIndicatorMultiOptionNumberOnly':
      return <ListOl />
    case 'SefIndicatorMultiOptionTextOnly':
      return <TextBulletListLtr />
    case 'SefIndicatorMultiOptionMixed':
      return <ListColumns />
    case 'NoRole':
      return <SvgCheckboxBlankCircle />
    default:
      return <SvgShoePrints />
  }
}

const CustomPillIconSection = ({ item, smallIcon }) => {
  if (!item) return null

  return (
    <IconWrapper smallIcon={smallIcon}>
      <CustomPillIconSelection item={item} />
    </IconWrapper>
  )
}

export const DashHomeStatsTable = ({ children }) => {
  return (
    <Table>
      <tbody>{children}</tbody>
    </Table>
  )
}

export const DashStatTableValueInner = ({
  avatarStyles,
  bgColor,
  canEditCheckbox,
  color,
  columnKey,
  cursor,
  fontBold,
  fullWidth,
  hoverLabel,
  icon: Icon,
  id,
  image,
  isCheckbox,
  isCustomStatusPill,
  isLink,
  isMultiStatusPill,
  isStatusPill,
  link,
  nullIcon: NullIcon,
  nullPillText,
  nullText,
  nullTextAlign,
  pillWidth,
  showCheckbox,
  smallIcon,
  toggleCheckbox,
  tooltipPosition,
  uppercase,
  value
}) => {
  if (isLink && link) {
    return <DashStatTableValueLink to={link}>{value}</DashStatTableValueLink>
  }

  if (image && hoverLabel) {
    if (typeof image === 'object') {
      const SvgImage = image
      return (
        <Tooltip content={hoverLabel} forceShow minWidth position="right" tinyTooltip>
          <TableCellSvgImage avatarStyles={avatarStyles}>
            <SvgImage />
          </TableCellSvgImage>
        </Tooltip>
      )
    }

    return (
      <Tooltip content={hoverLabel} forceShow minWidth position="right" tinyTooltip>
        <TableCellImage round src={image}></TableCellImage>
      </Tooltip>
    )
  }

  if (image) {
    if (typeof image === 'object') {
      const SvgImage = image
      return (
        <TableCellSvgImage avatarStyles={avatarStyles}>
          <SvgImage />
        </TableCellSvgImage>
      )
    }

    return <TableCellImage round src={image}></TableCellImage>
  }

  const isSvgIconCal = Icon === 'CalendarEventFill'
  const isSvgIconZero = Icon === 'CreativeCommonsZero'
  const isSvgIconCancel = Icon === 'CancelIcon'
  const isBodyTextIcon = Icon === 'BodyTextIcon'
  const isSvgIcon = [
    'CalendarEventFill',
    'CreativeCommonsZero',
    'CancelIcon',
    'BodyTextIcon'
  ].includes(Icon)

  if (isSvgIcon && hoverLabel) {
    return (
      <Tooltip content={hoverLabel} forceShow minWidth position="right" tinyTooltip>
        <IconWrapper smallIcon={smallIcon}>
          {isSvgIconCal ? (
            <SvgIconCal />
          ) : isSvgIconZero ? (
            <SvgIconZero />
          ) : isSvgIconCancel ? (
            <SvgIconCancel />
          ) : isBodyTextIcon ? (
            <SvgIconBodyText />
          ) : null}
        </IconWrapper>
      </Tooltip>
    )
  }

  if (isSvgIcon) {
    return (
      <IconWrapper smallIcon={smallIcon}>
        {isSvgIconCal ? (
          <SvgIconCal />
        ) : isSvgIconZero ? (
          <SvgIconZero />
        ) : isSvgIconCancel ? (
          <SvgIconCancel />
        ) : isBodyTextIcon ? (
          <SvgIconBodyText />
        ) : null}
      </IconWrapper>
    )
  }

  if (Icon && hoverLabel) {
    return (
      <Tooltip content={hoverLabel} forceShow minWidth position="right" tinyTooltip>
        <IconWrapper smallIcon={smallIcon}>
          <TableCellImage src={Icon} />
        </IconWrapper>
      </Tooltip>
    )
  }

  if (Icon) {
    return (
      <IconWrapper smallIcon={smallIcon}>
        <TableCellImage src={Icon} />
      </IconWrapper>
    )
  }

  if (!value && NullIcon) {
    return (
      <NullIconWrapper nullTextAlign={nullTextAlign}>
        <NullIcon />
        <NullText>{nullText || ''}</NullText>
      </NullIconWrapper>
    )
  }

  if (!value && nullText) {
    return (
      <NullIconWrapper nullTextAlign={nullTextAlign}>
        <NullText>{nullText}</NullText>
      </NullIconWrapper>
    )
  }

  if (!value && nullPillText) {
    return (
      <Pill
        bgColor={bgColor}
        fontBold={fontBold}
        fontColor={color}
        fullWidth={fullWidth}
        value={nullPillText}
        uppercase
      >
        {nullPillText}
      </Pill>
    )
  }

  if (isCheckbox && canEditCheckbox && showCheckbox) {
    if (value) {
      return <Checked onClick={() => toggleCheckbox(id)} />
    }

    return <Unchecked onClick={() => toggleCheckbox(id)} />
  }

  if (isCheckbox && showCheckbox) {
    return <span>{!!value ? 'Yes' : 'No'}</span>
  }

  if (isCheckbox) {
    return <ColumnHint>N/A</ColumnHint>
  }

  if (!value) return null

  if (isCustomStatusPill) {
    const customPillCount = value.length

    return value.map((item, index) => {
      const { multiPillBgColor, multiPillColor } = getMultiPillsPillColor({
        bgColor,
        color,
        item: item.value,
        columnKey
      })

      if (item.tooltip) {
        return (
          <Tooltip
            key={`tooltip-${index}`}
            content={item.tooltip}
            forceShow
            minWidth
            position={tooltipPosition || 'right'}
            tinyTooltip
          >
            <CustomMultiPill
              customPillCount={customPillCount}
              key={index}
              uppercase={uppercase}
              value={item.value}
              fullWidth={fullWidth}
            >
              <CustomStatusPill>
                {item.custom_pill_img_url && (
                  <CustomStatusPillImage value={item.value} src={item.custom_pill_img_url} />
                )}

                {item.custom_pill_icon && (
                  <CustomPillIconSection item={item} smallIcon={smallIcon} />
                )}

                {item.value && (
                  <CustomStatusPillValue
                    bgColor={multiPillBgColor}
                    columnKey={columnKey}
                    customPillCount={customPillCount}
                    fontColor={multiPillColor}
                  >
                    {item.value}
                  </CustomStatusPillValue>
                )}
              </CustomStatusPill>
            </CustomMultiPill>
          </Tooltip>
        )
      }

      return (
        <CustomMultiPill
          customPillCount={customPillCount}
          key={index}
          uppercase={uppercase}
          value={item.value}
          fullWidth={fullWidth}
        >
          <CustomStatusPill>
            {item.custom_pill_img_url && (
              <CustomStatusPillImage value={item.value} src={item.custom_pill_img_url} />
            )}

            {item.custom_pill_icon && <CustomPillIconSection item={item} smallIcon={smallIcon} />}

            {item.value && (
              <CustomStatusPillValue
                bgColor={multiPillBgColor}
                customPillCount={customPillCount}
                columnKey={columnKey}
                fontColor={multiPillColor}
              >
                {item.value}
              </CustomStatusPillValue>
            )}
          </CustomStatusPill>
        </CustomMultiPill>
      )
    })
  }

  if (isMultiStatusPill) {
    return value.map((item, index) => {
      const { multiPillBgColor, multiPillColor } = getMultiPillsPillColor({
        bgColor,
        color,
        item,
        columnKey
      })

      return (
        <MultiPill
          bgColor={multiPillBgColor}
          columnKey={columnKey}
          cursor={cursor}
          fontColor={multiPillColor}
          fullWidth={fullWidth}
          pillWidth={pillWidth}
          key={index}
          uppercase={uppercase}
          value={item}
        >
          {item}
        </MultiPill>
      )
    })
  }

  if (!isStatusPill) return <Fragment>{value}</Fragment>

  if (hoverLabel) {
    return (
      <Tooltip content={hoverLabel} forceShow minWidth position="right" tinyTooltip>
        <Pill
          bgColor={bgColor}
          fontBold
          fontColor={color}
          fullWidth={fullWidth}
          value={value}
          uppercase={uppercase}
        >
          {value}
        </Pill>
      </Tooltip>
    )
  }

  return (
    <Pill
      bgColor={bgColor}
      fontBold={fontBold}
      fontColor={color}
      fullWidth={fullWidth}
      value={value}
      uppercase={uppercase}
    >
      {value}
    </Pill>
  )
}

const DashStatTableValueLink = styled(Link)`
  color: ${th('text.dark')};
  text-decoration: underline;
`

export const DashStatTableValue = styled.td`
  font-size: ${props =>
    props.fontSize
      ? props.fontSize
      : ['small', 'smallButWrap'].includes(props.size)
      ? '12px'
      : '15px'};
  line-height: 1.35em;
  min-width: ${props => {
    if (props.minWidthMobile) return props.minWidthMobile
    if (props.primaryCell) return '150px'
    if (props.size === 'smallButWrap' && props.value) return '1%'
    if (props.size === 'smallButWrap' && !props.value) return '1%'

    if (!props.collapse && props.value) return '100px'
    if (props.collapse && props.value) return '1%'

    return '50px'
  }};
  padding: ${props => {
    if (props.smallIcon) return '3px 0 0'
    if (props.vPadding) return `${props.vPadding}px 10px`

    return '0 10px'
  }};
  text-align: ${props =>
    props.cellAlign === 'right' ? 'right' : props.center ? 'center' : 'left'};
  white-space: ${props =>
    props.size === 'smallButWrap'
      ? 'normal'
      : props.nowrap || props.collapse || props.size === 'small'
      ? 'nowrap'
      : 'normal'};

  @media screen and (min-width: 769px) {
    min-width: ${props => {
      if (props.minWidth) return props.minWidth
      if (props.primaryCell) return '250px'
      if (props.size === 'smallButWrap' && props.value) return '200px'
      if (props.size === 'smallButWrap' && !props.value) return '1%'

      if (!props.collapse && props.value) return '200px'
      if (props.collapse && props.value) return '1%'

      return 'auto'
    }};
  }
`

export const TableCellButtonWrapper = styled.div`
  margin: 10px 10px 10px 0;
  display: ${props => props.display || 'inline-block'};
`

export const BorderWrapper = styled.div`
  background: white;
  border: 1px solid ${th('borders.medium')};
  margin: 0;
  overflow: auto;
`

export const ResultsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: ${props => (props.dashboard ? '5px 0' : '0 16px')};

  @media screen and (min-width: 600px) {
    overflow-x: hidden;
    margin: ${props => (props.dashboard ? '10px -10px' : '0')};
    padding: ${props => (props.dashboard ? '10px 0' : props.noResults ? '16px 6px' : '10px 6px')};
  }

  @media screen and (min-width: 969px) {
    margin: ${props => (props.dashboard ? '10px -20px 0 -10px' : '0')};
  }
`

export const EditIconWrapper = styled.div`
  position: relative;
  ${props =>
    !props.overrideIconSize &&
    css`
      svg {
        height: 15px;
        width: 15px;
      }
    `}
`

export const LoadingMessageWrapper = styled.div`
  border: 2px solid ${th('borders.medium')};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.5em;
  margin: 0;
  min-height: 250px;
  padding: 20px;
  width: 100%;
`

export const NoResultsImage = styled.img`
  margin: 0;
  width: 100%;

  @media screen and (min-width: 769px) {
    width: ${props => props.width || '50%'};
  }
`

export const NoResultsButtonWrapper = styled.div`
  display: block;
  margin: 10px auto 0;
  text-align: center;
  width: max-content;
`

export const NoResultsImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const NoResultsMessageWrapper = styled.div`
  border: 2px solid ${th('borders.medium')};
  border-radius: 4px;
  line-height: 1.5em;
  margin: 0;
  padding: 20px;
  width: 100%;

  @media screen and (min-width: 769px) {
    margin: 0 ${props => (props.sideMargin ? '10px' : '0')};
  }
`

export const NoResultsMessageHeader = styled.h4`
  font-family: ${th('fonts.bold')};
  margin: 0;
  text-align: center;
`

export const NoResultsMessage = styled.div`
  line-height: 1.5em;
  margin: 0 auto;
  text-align: center;
  width: 80%;

  @media screen and (min-width: 769px) {
    margin: 0 auto;
    width: 50%;
  }
`

export const CardWrapper = styled.div`
  flex: ${props => determineCardFlex(props).default};
  margin: 0 0 10px;
  max-height: 100%;
  padding: 0;
  width: 0;

  @media screen and (min-width: 600px) {
    flex: ${props => determineCardFlex(props).w600};
    margin: 0 10px 20px;
  }

  @media screen and (min-width: 969px) {
    flex: ${props => determineCardFlex(props).w969};
  }
`

export const CornerIconWrapper = styled.div`
  background: ${props => (props.active ? th('secondary.light') : 'white')};
  border: 1px solid ${props => (props.active ? th('secondary.dark') : th('borders.default'))};
  border-radius: 50%;
  height: 35px;
  margin: 12px 10px 8px 12px;
  padding: 8px;
  position: absolute;
  right: 13px;
  width: 35px;

  &:hover {
    border: 1px solid ${th('primary.base')};
    background: ${th('transparent.base')};
    cursor: pointer;
  }
`
